import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["requestLink", "deactivatedIcon"];

  requestLinkTargetConnected() {
    this.updateLinkTargets();
  }

  denyLinkTargetConnected() {
    this.updateLinkTargets();
  }

  requestPermission() {
    Notification.requestPermission().then(() => {
      this.updateLinkTargets();
    });
  }

  updateLinkTargets() {
    if (!("Notification" in window)) {
      this.element.classList.add("d-none");
    } else if (Notification.permission === "granted") {
      this.requestLinkTarget.classList.add("d-none");
      this.deactivatedIconTarget.classList.remove("d-none");
    } else {
      this.requestLinkTarget.classList.remove("d-none");
      this.deactivatedIconTarget.classList.add("d-none");
    }
  }
}

import consumer from "./consumer";

consumer.subscriptions.create("NotificationsChannel", {
  received(data) {
    if (Notification.permission === "granted") {
      const notification = new Notification(data.title, data.options);
      notification.addEventListener("click", (event) => window.open(event.currentTarget.data.url, "_blank"));
    }
  },
});

if (document.documentElement.getAttribute('env') === 'test') {
  window.notifications = [];
  window.DefaultNotification = Notification;

  let permission = 'default';

  const handler = {
    construct(target, args) {
      var notification = new target(...args);
      window.notifications.push(notification);

      return notification;
    },

    get(target, property) {
      if (property === 'permission') {
        return permission;
      } else if (property === 'requestPermission') {
        permission = 'granted';

        return () => Promise.resolve();
      }

      return Reflect.get(...arguments);
    },
  };

  const ProxifiedNotification = new Proxy(Notification, handler);
  window.Notification = ProxifiedNotification;
}
